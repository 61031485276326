import React, { FunctionComponent } from 'react';
import Layout from '../components/layout/layout';
import { Locale } from '../models/locale';

interface IProps {
  pageContext: {
    intl: { language: Locale };
  };
}
const NotFoundPage: FunctionComponent<IProps> = ({ pageContext }) => (
  <Layout lang={pageContext.intl.language} basePath='/404'>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
